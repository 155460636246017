




























































import { Vue, Component } from "vue-property-decorator";

import { appName } from "@/env";
import { readHasAdminAccess } from "@/store/main/getters";
import { readUserProfile } from "@/store/main/getters";
import { dispatchUserLogOut } from "@/store/main/actions";

const routeGuardMain = async (to, from, next) => {
  if (to.path === "/main") {
    next("/main/dashboard");
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
  public appName = appName;
  private miniDrawer = false;

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get userId() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      return userProfile.user_id;
    } else {
      return "Guest";
    }
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
}
